import React, { useEffect, useState } from 'react';

import { AiFillEdit } from 'react-icons/ai';
import { FaCheckDouble } from 'react-icons/fa';
import { MdCancel, MdOutlineShare } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import { ProgressBar } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Select,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import NoPicture from '../../../assets/images/icons/driver-without-picture.png';
import pointStatus from '../../../assets/svgs/trip/pointStatus.svg';
import truckIcon from '../../../assets/svgs/trip/truckIcon.svg';
import Card from '../../../components/Card/Card';
import FloatActionButton from '../../../components/Generic/FloatActionButton';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import DefaultCommentModal from '../../../components/Modal/Template/DefaultCommentModal';
import DefaultModal from '../../../components/Modal/Template/DefaultModal';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { processCheck, processUncheck } from '../../../utils/exports';
import { limitChars } from '../../../utils/strings';
import { capitalizeWords } from '../../../utils/text/format/capitalizeWords';
import { descriptionStatusTrip, getStatusTransition } from '../../../utils/translate';
import ModalFreight from '../TravelManagement/components/TravelList/ModalFreight';

import CommentTab from './components/Comment/CommentTab';
import DocumentTab from './components/Document/DocumentTab';
import LinkListDrawer from './components/Drawers/LinkListDrawer';
import EventsTab from './components/Events/EventsTab';
import InformationTab from './components/Information/InformationTab';
import MapTrajectory from './components/Map/MapTrajectory';
import TrackingTab from './components/Tracking/TrackingTab';
import TrajectoryTab from './components/Trajectory/TrajectoryTab';

const TravelDetailPage = () => {
  const { identifier } = useParams();
  let { state } = useLocation();
  let { viewport } = useResponsiveCheck();

  let profileRole = localStorage.getItem('profile-role');

  const [entity, setEntity] = useState(state);
  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [isLoadingCheckpoints, setIsLoadingCheckpoints] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventsFull, setEventsFull] = useState([]);
  const [checkpoints, setCheckpoints] = useState([]);
  const [lastVersionApp, setLastVersionApp] = useState('Não encontrado');
  const [additionalInformation, setAdditionalInformation] = useState({});
  const [tripPercentage, setTripPercentage] = useState();
  const [currentDriverPosition, setCurrentDriverPosition] = useState({});
  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  const [load, setLoad] = useState(false);
  const [action, setAction] = useState(1);

  const [zoom, setZoom] = useState(5);
  const [center, setCenter] = useState({
    lat: -23.5792551,
    lng: -46.641581,
  });

  const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure();
  const { isOpen: isOpenFinish, onOpen: onOpenFinish, onClose: onCloseFinish } = useDisclosure();
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const { isOpen: isOpenModalLink, onOpen: onOpenModalLink, onClose: onCloseModalLink } = useDisclosure();

  const calcCarbon = entity?.carbonTrack?.calculated + entity?.carbonTrack?.retired;
  const calcCarbonFormatted = calcCarbon.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

  const onSubmit = async (action, description, onClose) => {
    setLoad(true);

    requests
      .addFreightComment(entity.identifier, { description: description })
      .then(async () => {
        toast.success(`Comentário da viagem #${entity?.code} enviado com sucesso!`);
        requests
          .changeStatusDriverFreight(entity?.identifier, action)
          .then((response) => {
            toast.success(`Viagem #${entity?.code} alterada com sucesso`);
            getEntity();
            onClose();
            setLoad(false);
          })
          .catch(() => {
            toast.error(`Problemas ao alterar a viagem #${entity?.code}, tente novamente ou entre em contato com o Administrador`);
            setLoad(false);
          });
      })
      .catch(() => {
        toast.error(`Erro ao enviar o comentário da viagem #${entity?.code} , tente novamente.`);
        setLoad(false);
      });
  };

  const percentage = (modality, status, typeExternalCode) => {
    let text;

    if (status === 'COMPLETED') {
      text = 'Viagem concluída';
    } else if (modality === 'FRETE_NAO_RASTREAVEL') {
      text = 'Porcentagem estimada de conclusão com base nos horários previstos';
    } else if (modality === 'APP_MOTORISTA' || typeExternalCode === 'OPENTECH') {
      text = 'Porcentagem estimada de conclusão com base na distância';
    } else {
      text = 'Carregando...';
    }

    return text;
  };

  const getEntity = () => {
    setIsLoadingEntity(true);

    requests
      .showDriverFreight(identifier)
      .then((response) => setEntity(response))
      .finally(() => setIsLoadingEntity(false));
  };

  const generateVersion = () => {
    return `Versão do APP: ${lastVersionApp}`;
  };

  const getEvents = () => {
    setIsLoadingEvents(true);

    requests
      .showEventsDriverFreight(identifier)
      .then((response) => setEvents(response))
      .finally(() => setIsLoadingEvents(false));
  };

  const getEventsFull = () => {
    // setIsLoadingEventsFull(true);
    requests
      .showEventsDriverFreight(identifier, 'full')
      .then((response) => {
        setEventsFull(response);
      })
      .finally(() => {
        // setIsLoadingEventsFull(false);
      });
  };

  const getCheckpoints = () => {
    setIsLoadingCheckpoints(true);
    requests
      .showCheckpointsDriverFreight(entity.identifier)
      .then((response) => {
        setAdditionalInformation(response?.additional);

        if (response?.checkpoints?.length > 0) {
          setCheckpoints(response?.checkpoints);

          if (entity?.modality == 'APP_MOTORISTA' && response?.lastVersionApp != null) {
            setLastVersionApp(response?.lastVersionApp);
          }
        }
      })
      .finally(() => {
        setIsLoadingCheckpoints(false);
      });
  };

  const getCurrentDriverPosition = () => {
    requests
      .getCurrentDriverPosition(identifier)
      .then((res) => setCurrentDriverPosition(res))
      .catch(() => {})
      .finally(() => {});
  };

  const fetchPercentageCompletion = async (identifier) => {
    try {
      const response = await requests.tripPercentage(identifier);
      setTripPercentage(response);
    } catch (error) {
      console.error('Erro ao buscar o percentual de conclusão:', error);
    }
  };

  const handleClickChangeStatus = (event) => {
    setIsLoadingEntity(true);
    let transition = getStatusTransition(entity?.status, event?.target?.value);
    if (!transition) {
      toast.error('Não foi possivel alterar o status da viagem, verifique o fluxograma para alteração de status');
      setIsLoadingEntity(false);
      return;
    }

    requests
      .changeStatusDriverFreight(entity?.identifier, transition)
      .then(() => {
        toast.success(
          `Status alterado de ${descriptionStatusTrip(entity?.status)} para ${descriptionStatusTrip(event?.target?.value)}`
        );
        window.location.reload();
      })
      .catch(() => {
        toast.error('Problemas ao alterar status da viagem, tente novamente ou entre em contato com o Administrador');
        setIsLoadingEntity(false);
      });
  };

  const checkStatus = (action, status) => {
    const cancel = ['DRAFT', 'CREATED', 'SENT_TO_DRIVER', 'ACCEPTED_BY_DRIVER', 'TRAVELLING'];
    const complete_travel = [
      'DELIVERED_WITH_SIGNATURE',
      'DELIVERED_WITHOUT_SIGNATURE',
      'SENT_TO_DRIVER',
      'ACCEPTED_BY_DRIVER',
      'TRAVELLING',
    ];

    switch (action) {
      case 'cancel':
        return cancel.indexOf(status) !== -1 ? true : false;
      case 'complete_travel':
        return complete_travel.indexOf(status) !== -1 ? true : false;
      default:
        return false;
    }
  };

  const checkPlate = () => {
    if (entity?.plates) {
      return entity?.plates;
    } else if (entity?.vehicle?.licensePlate) {
      return entity?.vehicle?.licensePlate;
    } else {
      return 'Não informado';
    }
  };

  const plates = checkPlate();

  const hiddenStatuses = ['DRAFT', 'REFUSED_BY_DRIVER', 'REFUSED_BY_DRIVER_HANDLED', 'HALTED', 'HALTED_HANDLED', 'CANCELLED'];

  useEffect(() => {
    getEntity();
    getCurrentDriverPosition();
    fetchPercentageCompletion(identifier);
  }, [action, identifier]);

  useEffect(() => {
    if (entity && entity.identifier) {
      getEvents();
      getCheckpoints();

      if (profileRole === 'ROLE_SYSTEM_ADMIN') {
        getEventsFull();
      }
    }
  }, [entity]);

  return (
    <Layout>
      <Menubar
        linkTree={[
          { link: '#', title: 'Transporte' },
          { link: state?.historyBack ?? '/logistic/trip', title: 'Viagens' },
        ]}
        title={`${entity && entity.code ? '#' + entity.code : 'Carregando...'}`}
        detailsTitle={`${entity && entity.code ? '#' + entity.code : 'Carregando...'}`}
        hideAllFilters={true}
        isRefreshLoading={isLoadingEntity}
        triggerRefreshData={() => getEntity()}
        showRefreshData={true}
        refreshSeconds={300}
      />
      {viewport > '1450' ? (
        <Grid minH="calc(100vh - 175px)" overflow="auto" mx="10px" gap="10px" templateColumns="700px 1fr" className="up-anim">
          <Flex gap="10px" direction="column">
            <Card p="20px">
              <Grid templateColumns="2fr 1fr" alignItems="center" gap="10px">
                <Flex direction="column" justify="space-between" gap="5px">
                  <Flex align="center" gap="20px">
                    {entity?.userDriverName && entity?.userDriverPicture ? (
                      <Image
                        src={`${process.env.REACT_APP_API_URL}${entity?.userDriverPicture?.replace('//profile', '/profile')}`}
                        alt="Foto de perfil do motorista"
                        w="80px"
                        h="80px"
                        objectFit="cover"
                      />
                    ) : (
                      <Image src={NoPicture} alt="Sem foto de motorista" w="80px" h="80px" />
                    )}

                    <Flex w="full" direction="column">
                      <Text fontSize="1.5rem" fontWeight="bold" color="primary" overflow="ellips">
                        {isLoadingEntity ? (
                          '...'
                        ) : (
                          <>
                            <Tooltip label={entity?.userDriverName}>
                              {entity?.userDriverName ? capitalizeWords(entity.userDriverName) : 'Nome não informado'}
                            </Tooltip>
                          </>
                        )}
                      </Text>

                      <Flex gap="4px" align="center" textColor="primary">
                        {entity?.userDriverTrips} {entity?.userDriverTrips === 1 ? 'Viagem realizada' : 'Viagens realizadas'}
                      </Flex>
                    </Flex>
                  </Flex>

                  {entity && (
                    <Tooltip label={entity?.carbonTrack?.retired > 0 ? 'Compensado' : 'Não compensado'}>
                      <Flex
                        w="max-content"
                        maxH="30px"
                        p="7px 11px"
                        border="1px"
                        borderColor="#2ECC71"
                        color="#2ECC71"
                        direction="row"
                        align="center"
                        borderRadius="10px"
                        gap="5px">
                        Pegada CO₂: {calcCarbonFormatted}t
                        {entity?.carbonTrack?.retired > 0 ? (
                          <Image src={processCheck} opacity={'1'} />
                        ) : (
                          <Image src={processUncheck} opacity={'1'} />
                        )}
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>

                {entity && entity.userDriverName && (
                  <Flex justify="space-between" direction="column">
                    <Flex direction="column">
                      <Text fontSize="10px">Id Viagem</Text>

                      <Text fontSize="19px" fontWeight="bold">
                        #{entity?.code}
                      </Text>
                    </Flex>

                    <Flex direction="column">
                      <Text fontSize="10px">Veículo</Text>

                      <Text fontSize="19px" fontWeight="bold">
                        {plates?.length > 16 ? <Tooltip label={plates}>{limitChars(plates, 16)}</Tooltip> : limitChars(plates, 16)}
                      </Text>
                    </Flex>

                    <Flex direction="column">
                      <Text fontSize="10px">Transportadora</Text>

                      <Text fontSize="19px" fontWeight="bold">
                        {isLoadingEntity ? '...' : <>{entity?.shippingCompanyName}</>}
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Grid>
            </Card>

            {profileRole !== 'ROLE_CLIENT' &&
              entity?.modality != 'MANUAL' &&
              (entity?.status === 'DRAFT' ||
                checkStatus('cancel', entity?.status) ||
                checkStatus('complete_travel', entity?.status)) && (
                <Card p="20px" gap="40px">
                  <Flex justify="space-between" align="center">
                    <Flex gap="10px">
                      <Flex bgColor="linkColor" p="10px 2px" borderRadius="2px"></Flex>

                      <Flex direction="column">
                        <Text fontSize="20px" fontWeight="bold">
                          Status da viagem
                        </Text>

                        <Text textColor="primary" fontSize="16px" opacity="1">
                          Alterar status manualmente
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex align="center" gap="10px">
                      {entity?.status === 'DRAFT' && (
                        <Button
                          leftIcon={<AiFillEdit color="#FFFFFF" size={16} />}
                          _hover={{ bgColor: '#33225C' }}
                          shadow="xl"
                          p="1.3rem"
                          pb="1.3rem"
                          pl="1rem"
                          pr="1rem"
                          borderRadius="10px"
                          bg="primary"
                          onClick={(decision) => {
                            if (decision) {
                              onOpenEdit();
                            }
                          }}>
                          <Text mr="10px" color="white">
                            Editar
                          </Text>
                        </Button>
                      )}

                      {checkStatus('cancel', entity?.status) && (
                        <DefaultCommentModal
                          action="cancel"
                          title={`Você tem certeza que deseja finalizar a viagem #${entity?.code}?`}
                          txtButton="Cancelar"
                          textButtonConfirm="Salvar"
                          icon={<MdCancel color="#FFFFFF" size={16} />}
                          load={load}
                          onSubmit={onSubmit}
                          isOpen={isOpenCancel}
                          onOpen={onOpenCancel}
                          onClose={onCloseCancel}
                          background="red"
                          hoverColor="#B53C2F"
                        />
                      )}

                      {checkStatus('complete_travel', entity?.status) && (
                        <DefaultCommentModal
                          action="complete_travel"
                          title={`Você tem certeza que deseja finalizar a viagem #${entity?.code}?`}
                          txtButton="Finalizar"
                          textButtonConfirm="Salvar"
                          icon={<FaCheckDouble color="#FFFFFF" size={16} />}
                          load={load}
                          onSubmit={onSubmit}
                          isOpen={isOpenFinish}
                          onOpen={onOpenFinish}
                          onClose={onCloseFinish}
                          hoverColor="#26A65B"
                        />
                      )}
                    </Flex>
                  </Flex>

                  <div style={{ position: 'relative', width: '100%', height: '0px' }}>
                    <ProgressBar percent={tripPercentage} filledBackground="#6C48C2" height="3px" />

                    {/* Ícone no início */}
                    <img
                      src={pointStatus}
                      style={{
                        position: 'absolute',
                        top: '-9px',
                        left: '-10px',
                        width: '20px',
                      }}
                      alt="Starting Point"
                    />

                    {/* Imagem do caminhão seguindo o progresso */}
                    <Tooltip
                      label={`${`${entity?.percentageCompletion}% - ${percentage(
                        entity.modality,
                        entity.status,
                        entity.typeExternalCode
                      )}`}`}>
                      <img
                        src={truckIcon}
                        style={{
                          width: '24px',
                          position: 'absolute',
                          top: '-43px',
                          left: `calc(${tripPercentage}% - 55px)`,
                          transition: 'left 0.5s ease',
                          transform: 'rotate(90deg)',
                        }}
                        alt="Truck Icon"
                      />
                    </Tooltip>

                    {/* Ícone no fim */}
                    <img
                      src={pointStatus}
                      style={{
                        position: 'absolute',
                        top: '-9px',
                        right: '-10px',
                        width: '20px',
                        filter: 'grayscale(100%)',
                      }}
                      alt="Ending Point"
                    />
                  </div>
                </Card>
              )}

            {entity?.modality === 'MANUAL' && (
              <Card p="20px">
                <Flex align="center" gap="10px">
                  <Flex justify="space-between">
                    <Flex bgColor="linkColor" p="10px 2px" ml={4} borderRadius="2px"></Flex>

                    <Box ml={4}>
                      <Text fontSize={'1.375rem'} fontWeight={'bold'}>
                        Status da viagem
                      </Text>
                      <Text fontSize={'1rem'}>Alterar status manualmente</Text>
                    </Box>
                  </Flex>

                  <Select
                    variant="primary"
                    w="fit-content"
                    name="status"
                    defaultValue={entity?.status}
                    isDisabled={entity?.statusAvailable?.length === 1}
                    onChange={(event) => handleClickChangeStatus(event)}>
                    {entity &&
                      entity?.statusAvailable?.length > 0 &&
                      entity.statusAvailable.map((status, key) => {
                        return (
                          <>
                            <option key={key} value={status.description}>
                              {status.translated}
                            </option>
                          </>
                        );
                      })}
                  </Select>
                </Flex>
              </Card>
            )}

            <Tabs position="relative" variant="unstyled" overflow="hidden" isFitted>
              <Card
                header={
                  <Flex position="relative" w="full">
                    <TabList w="full">
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Informações
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Trechos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Rastreio
                        </Text>
                      </Tab>
                      {eventsFull && eventsFull?.length > 0 && (
                        <Tab px="0px" py="10px">
                          <Text fontSize="14px" color="#6C48C2">
                            Eventos
                          </Text>
                        </Tab>
                      )}
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Documentos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Comentários
                        </Text>
                      </Tab>
                    </TabList>

                    <TabIndicator mt="-1.5px" bottom={0} height="2px" bg="#6C48C2" borderRadius="1px" />
                  </Flex>
                }>
                <Flex maxH="290px" overflowY="auto" minH="calc(100vh - 565px)">
                  <TabPanels p="15px" pt="0">
                    <InformationTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    <TrajectoryTab entity={entity} setCenter={setCenter} setZoom={setZoom} getEntity={getEntity} />
                    <TrackingTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    {eventsFull && eventsFull.length > 0 && <EventsTab events={eventsFull} setCenter={setCenter} setZoom={setZoom} />}
                    <DocumentTab files={entity?.files} entity={entity} getEntity={getEntity} />
                    <CommentTab entity={entity} />
                  </TabPanels>
                </Flex>
              </Card>
            </Tabs>
          </Flex>

          {!isLoadingEntity && entity && (
            <MapTrajectory
              viewport={viewport}
              w="full"
              h="auto"
              currentDriverPosition={currentDriverPosition}
              identifier={identifier}
              entity={entity}
              isLoadingEntity={isLoadingEntity}
              events={events}
              isLoadingEvents={isLoadingEvents}
              checkpoints={checkpoints}
              additional={additionalInformation}
              center={center}
              setCenter={setCenter}
              zoom={zoom}
              setZoom={setZoom}
            />
          )}
        </Grid>
      ) : (
        <Flex h="1190px" flexDirection="column" overflow="auto" mx="10px" gap="10px" className="up-anim">
          {!isLoadingEntity && entity && (
            <MapTrajectory
              viewport={viewport}
              w="full"
              h="500px"
              currentDriverPosition={currentDriverPosition}
              identifier={identifier}
              entity={entity}
              isLoadingEntity={isLoadingEntity}
              events={events}
              isLoadingEvents={isLoadingEvents}
              checkpoints={checkpoints}
              additional={additionalInformation}
              center={center}
              setCenter={setCenter}
              zoom={zoom}
              setZoom={setZoom}
            />
          )}

          <Flex gap="10px" direction="column">
            <Flex w="full" gap="10px" direction={viewport > '800' ? 'row' : 'column'}>
              <Card w="full" p="20px">
                <Flex w="full" h="full" direction="column" justify="space-between" gap="10px">
                  <Flex align="center" gap="20px">
                    {entity?.userDriverName && entity?.userDriverPicture ? (
                      <Image
                        src={`${process.env.REACT_APP_API_URL}${entity?.userDriverPicture?.replace('//profile', '/profile')}`}
                        alt="Foto de perfil do motorista"
                        w="60px"
                        h="60px"
                        objectFit="cover"
                      />
                    ) : (
                      <Image src={NoPicture} alt="Sem foto de motorista" w="80px" h="80px" />
                    )}

                    <Flex w="full" direction="column">
                      <Text fontSize="14px" fontWeight="bold" color="primary" overflow="ellips">
                        {isLoadingEntity ? (
                          '...'
                        ) : (
                          <>
                            <Tooltip label={entity?.userDriverName}>
                              {entity?.userDriverName ? capitalizeWords(entity.userDriverName) : 'Nome não informado'}
                            </Tooltip>
                          </>
                        )}
                      </Text>

                      <Flex gap="4px" align="center" textColor="primary" fontSize="11px">
                        {entity?.userDriverTrips} {entity?.userDriverTrips === 1 ? 'Viagem realizada' : 'Viagens realizadas'}
                      </Flex>
                    </Flex>
                  </Flex>

                  {entity && entity.userDriverName && (
                    <>
                      <Flex gap="20px">
                        <Flex direction="column">
                          <Text fontSize="11px">Id Viagem</Text>

                          <Text fontSize="14px" fontWeight="bold">
                            #{entity?.code}
                          </Text>
                        </Flex>

                        <Flex direction="column">
                          <Text fontSize="11px">Veículo</Text>

                          <Text fontSize="14px" fontWeight="bold">
                            {plates?.length > 16 ? <Tooltip label={plates}>{limitChars(plates, 16)}</Tooltip> : limitChars(plates, 16)}
                          </Text>
                        </Flex>

                        <Flex direction="column">
                          <Text fontSize="11px">Transportadora</Text>

                          <Text fontSize="14px" fontWeight="bold">
                            {isLoadingEntity ? '...' : <>{entity?.shippingCompanyName}</>}
                          </Text>
                        </Flex>
                      </Flex>
                    </>
                  )}

                  {entity && (
                    <Tooltip label={entity?.carbonTrack?.retired > 0 ? 'Compensado' : 'Não compensado'}>
                      <Flex
                        w="max-content"
                        maxH="30px"
                        p="7px 11px"
                        border="1px"
                        borderColor="#2ECC71"
                        color="#2ECC71"
                        direction="row"
                        align="center"
                        borderRadius="10px"
                        gap="5px">
                        Pegada CO₂: {calcCarbonFormatted}t
                        {entity?.carbonTrack?.retired > 0 ? (
                          <Image src={processCheck} opacity={'1'} />
                        ) : (
                          <Image src={processUncheck} opacity={'1'} />
                        )}
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
              </Card>

              {profileRole !== 'ROLE_CLIENT' &&
                entity?.modality != 'MANUAL' &&
                (entity?.status === 'DRAFT' ||
                  checkStatus('cancel', entity?.status) ||
                  checkStatus('complete_travel', entity?.status)) && (
                  <Card w="full" p="20px" gap="40px">
                    <Flex w="full" h="full" direction="column" align="center" justify="space-around" gap="20px">
                      <Flex w="full" gap="20px" justify="space-between" align="center">
                        <Flex gap="10px">
                          <Flex bgColor="linkColor" p="10px 2px" borderRadius="2px"></Flex>

                          <Flex direction="column">
                            <Text fontSize="14px" fontWeight="bold">
                              Status da viagem
                            </Text>

                            <Text textColor="primary" fontSize="11px" opacity="1">
                              Alterar status manualmente
                            </Text>
                          </Flex>
                        </Flex>

                        <Flex align="center" gap="10px" direction="column">
                          {entity?.status == 'DRAFT' && (
                            <Button
                              leftIcon={<AiFillEdit color="#FFFFFF" size={16} />}
                              _hover={{ bgColor: '#33225C' }}
                              shadow="xl"
                              p="1.3rem"
                              pb="1.3rem"
                              pl="1rem"
                              pr="1rem"
                              borderRadius="10px"
                              bg="primary"
                              onClick={(decision) => {
                                if (decision) {
                                  onOpenEdit();
                                }
                              }}>
                              <Text mr="10px" color="white">
                                Editar
                              </Text>
                            </Button>
                          )}

                          {checkStatus('cancel', entity?.status) && (
                            <DefaultCommentModal
                              action="cancel"
                              title={`Você tem certeza que deseja finalizar a viagem #${entity?.code}?`}
                              txtButton="Cancelar"
                              textButtonConfirm="Salvar"
                              icon={<MdCancel color="#FFFFFF" size={16} />}
                              load={load}
                              onSubmit={onSubmit}
                              isOpen={isOpenCancel}
                              onOpen={onOpenCancel}
                              onClose={onCloseCancel}
                              background="red"
                              hoverColor="#B53C2F"
                            />
                          )}

                          {checkStatus('complete_travel', entity?.status) && (
                            <DefaultCommentModal
                              action="complete_travel"
                              title={`Você tem certeza que deseja finalizar a viagem #${entity?.code}?`}
                              txtButton="Finalizar"
                              textButtonConfirm="Salvar"
                              icon={<FaCheckDouble color="#FFFFFF" size={16} />}
                              load={load}
                              onSubmit={onSubmit}
                              isOpen={isOpenFinish}
                              onOpen={onOpenFinish}
                              onClose={onCloseFinish}
                              hoverColor="#26A65B"
                            />
                          )}
                        </Flex>
                      </Flex>

                      <div style={{ position: 'relative', width: '100%', height: '0px' }}>
                        <ProgressBar percent={tripPercentage} filledBackground="#6C48C2" height="3px" />

                        {/* Ícone no início */}
                        <img
                          src={pointStatus}
                          style={{
                            position: 'absolute',
                            top: '-9px',
                            left: '-10px',
                            width: '20px',
                          }}
                          alt="Starting Point"
                        />

                        {/* Imagem do caminhão seguindo o progresso */}
                        <Tooltip label={`${tripPercentage}%`}>
                          <img
                            src={truckIcon}
                            style={{
                              width: '24px',
                              position: 'absolute',
                              top: '-43px',
                              left: `calc(${tripPercentage}% - 55px)`,
                              transition: 'left 0.5s ease',
                              transform: 'rotate(90deg)',
                            }}
                            alt="Truck Icon"
                          />
                        </Tooltip>

                        {/* Ícone no fim */}
                        <img
                          src={pointStatus}
                          style={{
                            position: 'absolute',
                            top: '-9px',
                            right: '-10px',
                            width: '20px',
                            filter: 'grayscale(100%)',
                          }}
                          alt="Ending Point"
                        />
                      </div>
                    </Flex>
                  </Card>
                )}

              {entity?.modality == 'MANUAL' && (
                <Card w="full" p="20px">
                  <Flex w="full" h="full" align="center" gap="10px">
                    <Flex justify="space-between">
                      <Flex bgColor="linkColor" p="10px 2px" ml={4} borderRadius="2px"></Flex>

                      <Box ml={4}>
                        <Text fontSize="14px" fontWeight={'bold'}>
                          Status da viagem
                        </Text>

                        <Text fontSize="11px">Alterar status manualmente</Text>
                      </Box>
                    </Flex>

                    <Select
                      variant="primary"
                      w="fit-content"
                      name="status"
                      defaultValue={entity?.status}
                      isDisabled={entity?.statusAvailable?.length === 1}
                      onChange={(event) => handleClickChangeStatus(event)}>
                      {entity &&
                        entity?.statusAvailable?.length > 0 &&
                        entity.statusAvailable.map((status, key) => {
                          return (
                            <>
                              <option key={key} value={status.description}>
                                {status.translated}
                              </option>
                            </>
                          );
                        })}
                    </Select>
                  </Flex>
                </Card>
              )}
            </Flex>

            <Tabs position="relative" variant="unstyled" overflow="hidden" isFitted>
              <Card
                header={
                  <Flex position="relative" w="full">
                    <TabList w="full" gap="5px" overflow="auto">
                      <Tab px="0px" py="10px">
                        <Text fontSize="10px" color="#6C48C2">
                          Informações
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="10px" color="#6C48C2">
                          Trechos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="10px" color="#6C48C2">
                          Rastreio
                        </Text>
                      </Tab>
                      {eventsFull && eventsFull?.length > 0 && (
                        <Tab px="0px" py="10px">
                          <Text fontSize="10px" color="#6C48C2">
                            Eventos
                          </Text>
                        </Tab>
                      )}
                      <Tab px="0px" py="10px">
                        <Text fontSize="10px" color="#6C48C2">
                          Documentos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="10px" color="#6C48C2">
                          Comentários
                        </Text>
                      </Tab>
                    </TabList>

                    <TabIndicator mt="-1.5px" bottom={0} height="2px" bg="#6C48C2" borderRadius="1px" />
                  </Flex>
                }>
                <Flex maxH="450px" overflowY="auto">
                  <TabPanels p="15px" pt="0">
                    <InformationTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    <TrajectoryTab entity={entity} setCenter={setCenter} setZoom={setZoom} getEntity={getEntity} />
                    <TrackingTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    {eventsFull && eventsFull.length > 0 && <EventsTab events={eventsFull} setCenter={setCenter} setZoom={setZoom} />}
                    <DocumentTab files={entity?.files} entity={entity} getEntity={getEntity} />
                    <CommentTab entity={entity} />
                  </TabPanels>
                </Flex>
              </Card>
            </Tabs>
          </Flex>
        </Flex>
      )}

      <DefaultModal
        label="Reprovar"
        title={'Certeza que deseja reprovar?'}
        enabled={false}
        subtitle={
          <>
            Você tem certeza que deseja reprovar esses dados?
            <br /> Ao reprovar o motorista receberá uma notificação com a sua mensagem de observação.
          </>
        }
        buttonColor="red"
        txtButton="Enviar reprovação"
        callback={(decision) => {
          // if (decision) {
          //   changeStatus(identifier, 'reject', { section: section, observation: observation });
          // }
        }}
      />

      <Flex className="float-button-modal">
        <ModalFreight
          entity={entity}
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          title="Edição de viagem"
          subTitle="Você pode salvar as alterações da sua viagem."
          action={action}
          setAction={setAction}
        />
      </Flex>

      {(permissions.isSystemAdmin || permissions.logisticTripFullManagement || permissions.logisticTripCreateShareLink) &&
        !hiddenStatuses.includes(entity?.status) && (
          <FloatActionButton
            saveButton={false}
            options={[
              <>
                <Button
                  variant={'floatingGreenButton'}
                  color="white"
                  rightIcon={<MdOutlineShare size={20} />}
                  onClick={() => {
                    onOpenModalLink();
                  }}>
                  Compartilhar viagem
                </Button>
              </>,
            ]}
            isOpen={isOpenFloatButton}
            setIsOpen={setIsOpenFloatButton}
          />
        )}

      <LinkListDrawer isOpen={isOpenModalLink} onClose={onCloseModalLink} identifier={identifier} entity={entity} />
    </Layout>
  );
};

export default TravelDetailPage;
