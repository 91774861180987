import React from 'react';

import '../styles/transportScheduleMonth.css';

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';

import TransportEventContent from './TransportEventContent';

const TransportScheduleMonth = ({ events, showWeekends, getStatusColor, isSystemAdmin, loadEventDetails }) => {
  return (
    <FullCalendar
      height="100%"
      plugins={[dayGridPlugin]}
      locale={ptBrLocale}
      events={events}
      weekends={showWeekends}
      firstDay={0}
      dayMaxEvents={2}
      showNonCurrentDates={false}
      dayHeaderFormat={{ weekday: 'long' }}
      moreLinkText={(num) => `+ ${num}`}
      eventContent={(eventInfo) => (
        <TransportEventContent
          eventInfo={eventInfo.event.extendedProps}
          getStatusColor={getStatusColor}
          isSystemAdmin={isSystemAdmin}
        />
      )}
      eventClick={(eventInfo) => {
        loadEventDetails(eventInfo.event.extendedProps.identifier);
      }}
      headerToolbar={{
        start: 'prev title next today',
        center: '',
        end: '',
      }}
    />
  );
};

export default TransportScheduleMonth;
