import React from 'react';

import '../styles/transportScheduleWeek.css';

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';

import TransportEventContent from './TransportEventContent';

const TransportScheduleWeek = ({ events, showWeekends, getStatusColor, isSystemAdmin, loadEventDetails }) => {
  return (
    <FullCalendar
      height="100%"
      initialView="timeGridWeek"
      plugins={[dayGridPlugin, timeGridPlugin]}
      locale={ptBrLocale}
      events={events}
      weekends={showWeekends}
      allDaySlot={false}
      nowIndicator
      eventContent={(eventInfo) => (
        <TransportEventContent
          eventInfo={eventInfo.event.extendedProps}
          getStatusColor={getStatusColor}
          isSystemAdmin={isSystemAdmin}
        />
      )}
      eventClick={(eventInfo) => {
        loadEventDetails(eventInfo.event.extendedProps.identifier);
      }}
      dayHeaderFormat={{
        weekday: 'long',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }}
      headerToolbar={{
        start: 'prev title next today',
        center: '',
        end: '',
      }}
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
      }}
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
      }}
    />
  );
};

export default TransportScheduleWeek;
