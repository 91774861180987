import React from 'react';

import { MdTurnedIn } from 'react-icons/md';

import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';

import { getIconByText } from './getIconByText';

const TransportEventContent = ({ eventInfo, getStatusColor, isSystemAdmin }) => {
  return (
    <Flex h="full" w="fit-content" bgColor="#F5F5F5" align="center" gap="10px" border="1px solid #E3E3E3" cursor="pointer">
      <Box h="full" minW="7px" bgColor={eventInfo.modality === 'RECEBIMENTO' ? '#6c48c2' : '#a885f9'} />

      <Flex h="full" w="full" align="center" justify="space-between" gap="10px">
        <Flex direction="column">
          <Tooltip label={eventInfo?.companyName}>
            <Text w="130px" fontSize="14px" fontWeight="medium" textColor="primary" isTruncated>
              {eventInfo?.companyName}
            </Text>
          </Tooltip>

          <Tooltip label={`Doca ${eventInfo.dockNumber}`}>
            <Text w="fit-content" fontSize="12px" textColor="#A6A6A6">
              Doca {eventInfo.dockNumber}
            </Text>
          </Tooltip>
        </Flex>

        <Flex h="full" w="full" justify="flex-end" gap="10px">
          <Tooltip label={eventInfo.modality}>
            <Image w="25px" src={getIconByText(eventInfo.modality)} />
          </Tooltip>

          <Tooltip label={eventInfo.stageModalityFormatted}>
            <Box h="fit-content">
              <MdTurnedIn size="20px" color={getStatusColor(eventInfo.stageModality)} />
            </Box>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TransportEventContent;
