import React, { useEffect, useState } from 'react';

import { ErrorMessage } from 'formik';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { Flex, FormControl, TabPanel, Text } from '@chakra-ui/react';

import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import requests from '../../../../services/requests';

const ResponsibleUsersDataForm = ({ values, setFieldValue, isPreviwer, isDraft }) => {
  const [responsibleUsersOptions, setResponsibleUsersOptions] = useState([]);

  const loadResponsibleUsersOptions = (setResponsibleUsersOptions) => {
    requests
      .listOperationResponsibleUsersAction('all')
      .then((response) => {
        var data = [];

        response.comercial.length > 0 &&
          response.comercial.forEach((res) => {
            data.push({
              label: res.name,
              value: res.identifier,
              email: res.email,
              type: 'comercial',
            });
          });

        response.tax.length > 0 &&
          response.tax.forEach((res) => {
            data.push({
              label: res.name,
              value: res.identifier,
              email: res.email,
              type: 'tax',
            });
          });

        response.operator.length > 0 &&
          response.operator.forEach((res) => {
            data.push({
              label: res.name,
              value: res.identifier,
              email: res.email,
              type: 'operator',
            });
          });

        response.financial.length > 0 &&
          response.financial.forEach((res) => {
            data.push({
              label: res.name,
              value: res.identifier,
              email: res.email,
              type: 'financial',
            });
          });

        response.director.length > 0 &&
          response.director.forEach((res) => {
            data.push({
              label: res.name,
              value: res.identifier,
              email: res.email,
              type: 'director',
            });
          });

        response.logistic.length > 0 &&
          response.logistic.forEach((res) => {
            data.push({
              label: res.name,
              value: res.identifier,
              email: res.email,
              type: 'logistic',
            });
          });

        setResponsibleUsersOptions(data);
      })
      .catch((error) => {
        alert(error);
        toast.error('Erro ao carregar lista de usuários responsáveis, recarregue a página e tente novamente');
      });
  };

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
    }),
  });

  useEffect(() => {
    loadResponsibleUsersOptions(setResponsibleUsersOptions);
  }, []);

  return (
    <TabPanel p="30px" display="flex" flexDirection="column" gap="10px">
      <FormControl>
        <LabelDefault
          text={
            <Flex gap="4px" alignItems="center">
              <Text>Logística</Text>
              {isDraft && <Text fontSize="small"> (opcional)</Text>}
            </Flex>
          }
        />
        <Select
          isClearable
          styles={selectCustomStyles()}
          options={responsibleUsersOptions.filter((e) => e.type == 'logistic')}
          name="responsibleUsers.logistic"
          className="outline-none transform-none text-black"
          placeholder="Selecione o responsável logística"
          isDisabled={isPreviwer}
          value={responsibleUsersOptions.find((selected) => selected.value === values.responsibleUsers.logistic?.value)}
          onChange={(option) => {
            setFieldValue('responsibleUsers.logistic', option);
          }}
        />
        <ErrorMessage component="p" className="error-message-error" name="responsibleUsers.logistic" />
      </FormControl>

      <FormControl>
        <LabelDefault
          text={
            <Flex gap="4px" alignItems="center">
              <Text>Comercial</Text>
              {isDraft && <Text fontSize="small"> (opcional)</Text>}
            </Flex>
          }
        />
        <Select
          isClearable
          styles={selectCustomStyles()}
          options={responsibleUsersOptions.filter((e) => e.type == 'comercial')}
          name="responsibleUsers.comercial"
          className="outline-none transform-none text-black"
          placeholder="Selecione o responsável comercial"
          isDisabled={isPreviwer}
          value={responsibleUsersOptions.find((selected) => selected.value === values.responsibleUsers.comercial?.value)}
          onChange={(option) => {
            setFieldValue('responsibleUsers.comercial', option);
          }}
        />
        <ErrorMessage component="p" className="error-message-error" name="responsibleUsers.comercial" />
      </FormControl>
      <FormControl>
        <LabelDefault
          text={
            <Flex gap="4px" alignItems="center">
              <Text>Comercial (adicional)</Text>
              {isDraft && <Text fontSize="small"> (opcional)</Text>}
            </Flex>
          }
        />
        <Select
          isClearable
          styles={selectCustomStyles()}
          isDisabled={isPreviwer}
          options={responsibleUsersOptions.filter((e) => e.type == 'comercial')}
          name="responsibleUsers.additionalComercial"
          className="outline-none transform-none text-black"
          placeholder="Selecione o responsável comercial (adicional)"
          value={responsibleUsersOptions.find((selected) => selected.value === values.responsibleUsers.additionalComercial?.value)}
          onChange={(option) => {
            setFieldValue('responsibleUsers.additionalComercial', option);
          }}
        />
        <ErrorMessage component="p" className="error-message-error" name="responsibleUsers.additionalComercial" />
      </FormControl>

      <FormControl>
        <LabelDefault
          text={
            <Flex gap="4px" alignItems="center">
              <Text>Tributário</Text>
              {isDraft && <Text fontSize="small"> (opcional)</Text>}
            </Flex>
          }
        />
        <Select
          isClearable
          styles={selectCustomStyles()}
          options={responsibleUsersOptions.filter((e) => e.type == 'tax')}
          name="responsibleUsers.tax"
          className="outline-none transform-none text-black"
          placeholder="Selecione o responsável tributário"
          isDisabled={isPreviwer}
          value={responsibleUsersOptions.find((selected) => selected.value === values.responsibleUsers.tax?.value)}
          onChange={(option) => {
            setFieldValue('responsibleUsers.tax', option);
          }}
        />
        <ErrorMessage component="p" className="error-message-error" name="responsibleUsers.tax" />
      </FormControl>

      <FormControl>
        <LabelDefault
          text={
            <Flex gap="4px" alignItems="center">
              <Text>Operacional</Text>
              {isDraft && <Text fontSize="small"> (opcional)</Text>}
            </Flex>
          }
        />
        <Select
          isClearable
          styles={selectCustomStyles()}
          options={responsibleUsersOptions.filter((e) => e.type == 'operator')}
          name="responsibleUsers.operator"
          className="outline-none transform-none text-black"
          placeholder="Selecione o responsável operacional"
          isDisabled={isPreviwer}
          value={responsibleUsersOptions.find((selected) => selected.value === values.responsibleUsers.operator?.value)}
          onChange={(option) => {
            setFieldValue('responsibleUsers.operator', option);
          }}
        />
        <ErrorMessage component="p" className="error-message-error" name="responsibleUsers.operator" />
      </FormControl>

      <FormControl>
        <LabelDefault
          text={
            <Flex gap="4px" alignItems="center">
              <Text>Financeiro</Text>
              {isDraft && <Text fontSize="small"> (opcional)</Text>}
            </Flex>
          }
        />
        <Select
          isClearable
          styles={selectCustomStyles()}
          options={responsibleUsersOptions.filter((e) => e.type == 'financial')}
          name="responsibleUsers.financial"
          className="outline-none transform-none text-black"
          placeholder="Selecione o responsável financeiro"
          isDisabled={isPreviwer}
          value={responsibleUsersOptions.find((selected) => selected.value === values.responsibleUsers.financial?.value)}
          onChange={(option) => {
            setFieldValue('responsibleUsers.financial', option);
          }}
        />
        <ErrorMessage component="p" className="error-message-error" name="responsibleUsers.financial" />
      </FormControl>

      <FormControl>
        <LabelDefault
          text={
            <Flex gap="4px" alignItems="center">
              <Text>Diretoria/Sócios</Text>
              {isDraft && <Text fontSize="small"> (opcional)</Text>}
            </Flex>
          }
        />
        <Select
          isClearable
          styles={selectCustomStyles()}
          options={responsibleUsersOptions.filter((e) => e.type == 'director')}
          name="responsibleUsers.director"
          className="outline-none transform-none text-black"
          placeholder="Selecione o responsável diretor"
          isDisabled={isPreviwer}
          value={responsibleUsersOptions.find((selected) => selected.value === values.responsibleUsers.director?.value)}
          onChange={(option) => {
            setFieldValue('responsibleUsers.director', option);
          }}
        />
        <ErrorMessage component="p" className="error-message-error" name="responsibleUsers.director" />
      </FormControl>
    </TabPanel>
  );
};

export default ResponsibleUsersDataForm;
