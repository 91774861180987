import moment from 'moment';
import { MdHelp } from 'react-icons/md';

import { Box, Text, Tooltip } from '@chakra-ui/react';

import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
import formatDocument from '../../../../utils/fields/masks/formatDocument';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

export const commonListConfig = {
  title: 'Especificação operacional',
  categories: [
    {
      title: 'Status',
      value: 'status',
      align: 'center',
      variantTag: 'softDashed',
    },
    {
      title: 'Filiais',
      value: 'renderHTMLFiliais', // Valor exclusivo
      align: 'center',
      renderHTML: (item) => {
        return (
          <Text display={'flex'} gap="3px" alignItems={'center'} justifyContent={'center'}>
            {item?.registration?.vendemmiaOperationUnit?.length > 0 ? (
              <>
                {item.registration.vendemmiaOperationUnit.length}
                <Tooltip
                  label={(() => {
                    let units = item.registration.vendemmiaOperationUnit;
                    let result = [];
                    for (let i in units) {
                      result.push(units[i].label);
                    }
                    return result.join(', ');
                  })()}>
                  <Box>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              </>
            ) : (
              <>-</>
            )}
          </Text>
        );
      },
    },
    {
      title: 'CNPJ',
      value: 'companyOperationDocumentNumber',
      type: 'label',
      align: 'center',
      formatted: (value) => formatDocument(value),
    },
    {
      title: 'Razão Social',
      value: 'companyName',
    },
    {
      title: 'Versão',
      value: 'version',
      align: 'center',
      type: 'label',
    },
    {
      title: 'Data de Cadastro',
      value: 'createdAt',
      align: 'center',
      formatted: (value) => moment(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Data de expiração',
      value: 'expiresAt',
      align: 'center',
      formatted: (value) => value && value !== '-' ? moment(value).format('DD/MM/YYYY') : '-',
    },
    {
      title: 'Pendências',
      value: 'renderHTMLPendencias', // Valor exclusivo
      align: 'center',
      renderHTML: (item) => {
        switch (item.status) {
          case 'RASCUNHO':
          case 'EXPIRADO':
            return null;

          case 'ASSINADO':
            return <Text>Sem pendências</Text>;

          case 'CONFIRMADO':
            return <Text>Aguardando envio para assinatura</Text>;

          case 'CADASTRADO': {
            const { qttPending, whoIsMissingApproval } = item?.responsibleUsers?.status || {};

            return (
              <Text display={'flex'} gap={'3px'} alignItems={'center'} justifyContent={'center'}>
                {qttPending}
                {qttPending > 0 ? (
                  <Tooltip label={whoIsMissingApproval}>
                    <Box>
                      <MdHelp color="#422C76" />
                    </Box>
                  </Tooltip>
                ) : (
                  <Text>sem pendências</Text>
                )}
              </Text>
            );
          }

          case 'AGUARDANDO_ASSINATURA': {
            const { qttPendingSign, whoIsMissingSign } = item?.responsibleUsers?.status || {};

            return (
              <Text display={'flex'} gap={'3px'} alignItems={'center'} justifyContent={'center'}>
                {qttPendingSign}
                {qttPendingSign > 0 ? (
                  <Tooltip label={whoIsMissingSign}>
                    <Box>
                      <MdHelp color="#422C76" />
                    </Box>
                  </Tooltip>
                ) : (
                  <Text>sem pendências</Text>
                )}
              </Text>
            );
          }

          default:
            return null;
        }
      },
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  mainCategoryMobile: 'companyName',
  hasPagination: true,
  hasCollapsed: false,
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 50,
  hasDetailsButton: true,
  detailsRedirectEndpoint: '/vendemmia/operation-specification/detail/${identifier}/isPreviwer',
  tags: ['status'],
  statusPreset: [
    { color: '#2ECC71', label: 'Assinado', value: 'ASSINADO' },
    { color: '#d3ab0d', label: 'Aguardando assinatura', value: 'AGUARDANDO_ASSINATURA' },
    { color: '#85828C', label: 'Rascunho', value: 'RASCUNHO' },
    { color: '#E74C3C', label: 'Expirado', value: 'EXPIRADO' },
  ],
  resizeRows: '180px 60px 200px 1fr 160px 180px 160px 120px 120px',
};
