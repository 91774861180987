import React from 'react';

import { MdTurnedIn } from 'react-icons/md';

import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';

import { getIconByText } from './getIconByText';

const TransportScheduleKanban = ({ events, getStatusColor, loadEventDetails }) => {
  const columns = [
    { name: 'Cadastrado', status: 'CADASTRADO' },
    { name: 'Agendado', status: 'AGENDADO' },
    { name: 'Chegou', status: 'CHEGOU' },
    { name: 'No pátio', status: 'NO_PATIO' },
    { name: 'Na doca', status: 'NA_DOCA' },
    { name: 'Concluído', status: 'CONCLUIDO' },
    { name: 'Cancelado', status: 'CANCELADO' },
    { name: 'Recusado', status: 'RECUSADO' },
  ];

  return (
    <Flex h="full" w="full" minW="1800px" p="10px" className="up-anim">
      {columns.map((column, index) => (
        <Flex h="full" w="full" direction="column" align="center" gap="10px" overflow="auto" key={index}>
          <Flex
            w="full"
            p="20px"
            justify="center"
            shadow="md"
            border="1px solid #70707029"
            borderLeftRadius={index === 0 && '8px'}
            borderRightRadius={index === columns.length - 1 && '8px'}>
            <Text fontSize="16px" textColor="primary">
              {column.name}
            </Text>
          </Flex>

          <Flex h="full" maxH="calc(100vh - 360px)" w="full" px="10px" pb="10px" direction="column" gap="10px" overflowY="auto">
            {events
              .filter((event) => event.stageModality === column.status)
              .map((entity, i) => (
                <Flex
                  key={i}
                  minH="70px"
                  w="full"
                  bgColor="#F5F5F5"
                  align="center"
                  gap="10px"
                  border="1px solid #E3E3E3"
                  cursor="pointer"
                  transition="transform 0.2s ease-in-out"
                  _hover={{
                    transform: 'scale(1.1)',
                  }}
                  onClick={() => {
                    loadEventDetails(entity.identifier);
                  }}>
                  <Box h="full" minW="7px" bgColor={entity.modality === 'RECEBIMENTO' ? '#6c48c2 ' : '#a885f9'} />

                  <Flex h="full" w="full" align="center" justify="space-between" gap="10px">
                    <Flex direction="column">
                      <Tooltip label={entity?.companyName} placement="left">
                        <Text w="110px" fontSize="14px" fontWeight="medium" textColor="primary" isTruncated>
                          {entity?.companyName}
                        </Text>
                      </Tooltip>

                      <Tooltip label={`Doca ${entity.dockNumber}`} placement="left">
                        <Text w="fit-content" fontSize="12px" textColor="#A6A6A6">
                          Doca {entity.dockNumber}
                        </Text>
                      </Tooltip>
                    </Flex>

                    <Flex h="full" w="full" justify="space-between">
                      <Tooltip label={entity.modality} placement="left">
                        <Image w="25px" src={getIconByText(entity.modality)} />
                      </Tooltip>

                      <Tooltip label={entity.stageModalityFormatted} placement="left">
                        <Box h="fit-content">
                          <MdTurnedIn size="20px" color={getStatusColor(entity.stageModality)} />
                        </Box>
                      </Tooltip>
                    </Flex>
                  </Flex>
                </Flex>
              ))}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default TransportScheduleKanban;
