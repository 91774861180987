import React from 'react';

import { Button, Flex, Image } from '@chakra-ui/react';

import iconAgenda from '../../../../assets/images/iconAgenda.svg';
import iconKanban from '../../../../assets/images/iconKanban.svg';

const HeaderCustomCard = ({ selectedTab, selectedAgendaTab, setSelectedAgendaTab, handleTabChange, isSystemAdmin }) => {
  const optionsSchedule = ['Dia', 'Semana', 'Mês'];

  const tabsOptions = [
    ...(isSystemAdmin === 'ROLE_SYSTEM_ADMIN' ? [{ label: 'Agenda', icon: iconAgenda }] : []),
    ...(isSystemAdmin === 'ROLE_SYSTEM_ADMIN' ? [{ label: 'Kanban', icon: iconKanban }] : []),
    // { label: 'Circuito', icon: iconCircuit },
    // { label: 'Pátio', icon: iconPatio },
  ];

  const CustomButton = ({ isSelected, onClick, children, width = '120px' }) => {
    return (
      <Button
        h="full"
        bg={isSelected ? '#F2E9FFAB' : 'transparent'}
        w={width}
        borderRadius="0px"
        color="primary"
        _hover={{ bgColor: '#F2E9FFAB' }}
        onClick={onClick}>
        <Flex w="full" align="center" justify="center" gap="10px">
          {children}
        </Flex>
      </Button>
    );
  };

  return (
    <Flex w="full" align="center" justify="flex-end">
      <Flex gap="40px" align="center">
        {selectedTab === 'Agenda' && (
          <Flex h="49px" justify="center" borderRadius="8px" shadow="2xl" className="up-anim">
            {optionsSchedule.map((option, index) => (
              <CustomButton
                key={index}
                isSelected={selectedAgendaTab === option}
                onClick={() => setSelectedAgendaTab(option)}
                width="100px">
                <p fontWeight="bold">{option}</p>
              </CustomButton>
            ))}
          </Flex>
        )}

        {isSystemAdmin === 'ROLE_SYSTEM_ADMIN' && (
          <Flex h="49px" justify="center" borderRadius="8px" shadow="2xl" className="up-anim">
            {tabsOptions.map((tab, index) => (
              <CustomButton key={index} isSelected={selectedTab === tab.label} onClick={() => handleTabChange(tab)}>
                <Image src={tab.icon} />

                <p fontWeight="bold">{tab.label}</p>
              </CustomButton>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default HeaderCustomCard;
