import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@chakra-ui/layout';
import { Checkbox, useDisclosure } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import profile from '../../../services/profile';
import requests from '../../../services/requests';
import { filterOptionsTransportSchedule } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import FormEventScheduling from './components/FormEventScheduling';
import HeaderCustomCard from './components/HeaderCustomCard';
import TransportScheduleDay from './components/TransportScheduleDay';
import TransportScheduleKanban from './components/TransportScheduleKanban';
import TransportScheduleMonth from './components/TransportScheduleMonth';
import TransportScheduleWeek from './components/TransportScheduleWeek';

const TransportSchedulePage = () => {
  const hasPermission = permissions.warehouseStockAging;
  const isSystemAdmin = profile.role;

  const [filterOptions, setFilterOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);

  const [eventsDetails, setEventsDetails] = useState([]);

  const [selectedTab, setSelectedTab] = useState('Agenda');
  const [selectedAgendaTab, setSelectedAgendaTab] = useState('Dia');

  const [showWeekends, setShowWeekends] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const loadEvents = async (filters) => {
    const res = await executeRequest({
      action: () => requests.getListCalendarEvents(filters),
      setIsLoading,
    });

    setEvents(res.data);
  };

  const loadEventDetails = async (identifier) => {
    const res = await executeRequest({
      action: () => requests.getEventDetails(identifier),
      setIsLoading,
    });

    setEventsDetails(res);

    onOpen();
  };

  const toggleWeekends = () => {
    setShowWeekends((prevShowWeekends) => !prevShowWeekends);
  };

  const formattedEvents = events.map((event) => {
    const stagePriority = ['Concluído', 'Chegou', 'Agendado'];

    const priorityStage = stagePriority
      .map((stageName) => event.stage?.find((stage) => stage.stage === stageName))
      .find((stage) => stage);

    const start = priorityStage ? priorityStage.happenedAt.replace(' ', 'T') : event.expectedAt?.replace(' ', 'T') || null;

    const end = priorityStage
      ? new Date(new Date(priorityStage.happenedAt).getTime() + 60 * 60000).toISOString().replace(' ', 'T')
      : event.expectedAt
      ? new Date(new Date(event.expectedAt).getTime() + 60 * 60000).toISOString().replace(' ', 'T')
      : null;

    return {
      identifier: event.identifier,
      companyName: event.companyWarehouse?.companyName ?? '-',
      code: event?.code ?? '-',
      dockNumber: event.dockNumber ?? '-',
      vehiclePlates: event.vehiclePlates ?? '-',
      stageModality: event.currentStageModality?.slug ?? '-',
      stageModalityFormatted: event.currentStageModality?.name ?? '-',
      modality: event.modality ?? '-',
      start,
      end,
    };
  });

  const statusColors = {
    CADASTRADO: '#F1C40F',
    AGENDADO: '#3498DB',
    CHEGOU: '#4CAF50',
    NO_PATIO: '#8E44AD',
    NA_DOCA: '#FF9800',
    CONCLUIDO: '#2E7D32',
    CANCELADO: '#F44336',
    RECUSADO: '#B71C1C',
    default: '#422C76',
  };

  const getStatusColor = (status) => statusColors[status] || statusColors.default;

  const tabs = useMemo(
    () => ({
      Agenda: {
        Dia: (
          <TransportScheduleDay
            events={formattedEvents}
            showWeekends={showWeekends}
            getStatusColor={getStatusColor}
            isSystemAdmin={isSystemAdmin}
            loadEventDetails={loadEventDetails}
          />
        ),
        Semana: (
          <TransportScheduleWeek
            events={formattedEvents}
            showWeekends={showWeekends}
            getStatusColor={getStatusColor}
            isSystemAdmin={isSystemAdmin}
            loadEventDetails={loadEventDetails}
          />
        ),
        Mês: (
          <TransportScheduleMonth
            events={formattedEvents}
            showWeekends={showWeekends}
            getStatusColor={getStatusColor}
            isSystemAdmin={isSystemAdmin}
            loadEventDetails={loadEventDetails}
          />
        ),
      },

      Kanban: <TransportScheduleKanban events={formattedEvents} getStatusColor={getStatusColor} loadEventDetails={loadEventDetails} />,
      // Circuito: <TransportScheduleCircuit />,
      // Pátio: <TransportScheduleCourtyard />,
    }),
    [events, showWeekends]
  );

  useEffect(() => {
    const loadFilterOptions = async () => {
      const filters = await filterOptionsTransportSchedule();
      setFilterOptions(filters);
    };
    loadFilterOptions();
  }, []);

  return (
    <Page
      screen="warehouse-transport-schedule"
      title="Agenda de transporte"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Processo, empresa ou ref"
      hasPermission={hasPermission}
      load={loadEvents}
      filterOptions={filterOptions}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      showPeriodFilter={false}>
      <Card
        h="calc(100vh - 192px)"
        m="10px"
        header={
          <HeaderCustomCard
            selectedTab={selectedTab}
            selectedAgendaTab={selectedAgendaTab}
            setSelectedAgendaTab={setSelectedAgendaTab}
            handleTabChange={({ label }) => setSelectedTab(label)}
            isSystemAdmin={isSystemAdmin}
          />
        }>
        {selectedTab === 'Agenda' ? (
          <Box h="full" p="10px" mb="20px" className="up-anim">
            <Checkbox iconColor="primary" textColor="primary" isChecked={showWeekends} onChange={toggleWeekends}>
              Exibir fins de semana
            </Checkbox>

            {tabs.Agenda[selectedAgendaTab]}
          </Box>
        ) : (
          tabs[selectedTab]
        )}
      </Card>

      <CommonDrawer heading={'Agendamento'} placement="left" isOpen={isOpen} onClose={onClose}>
        <FormEventScheduling events={eventsDetails} getStatusColor={getStatusColor} />
      </CommonDrawer>
    </Page>
  );
};

export default TransportSchedulePage;
