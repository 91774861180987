import { useEffect, useState } from 'react';

import { MdAccountCircle, MdAdd, MdOpenInNew } from 'react-icons/md';
import * as yup from 'yup';

import { Button, Link, Text, useDisclosure } from '@chakra-ui/react';

import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import { DynamicForm, DynamicFormBody, DynamicFormButton, DynamicFormFooter } from '../../../components/CommonForm';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../components/CommonModal/CommonModal';
import Page from '../../../components/Page';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import closeFloatActionButton from '../../../utils/actions/modal/closeFloatActionButton';
import { handleDocuments } from '../../../utils/documents/handleDocuments';
import { filterOptionsProtest } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';
import addParametersToURL from '../../../utils/url/addParametersToURL ';
import hasSpecificURLParameters from '../../../utils/url/hasSpecificURLParameter';

import { commonListConfig } from './components/commonListConfig';
import { DynamicFormProtestLetters } from './components/DynamicFormProtestLetters';
import { convertDateToInputFormat } from './Helpers/convertDateToInputFormat';

const validationSchema = yup.object().shape({
  process: yup.string().required('Campo obrigatório'),
  date: yup.string().required('Campo obrigatório'),
  suppliers: yup.array().of(
    yup.object().shape({
      documentNumberFormatted: yup.string().required('Campo obrigatório'),
    })
  ),
});

// Constantes para mensagens de sucesso e erro
const MESSAGES = {
  emailSuccess: 'E-mail enviado com sucesso',
  emailError: 'A operação falhou, tente novamente em breve',
  createSuccess: 'O protesto foi criado com sucesso',
  createError: 'Um erro ocorreu ao gerar o protesto. Por favor, tente novamente em breve.',
  editSuccess: 'O protesto foi editado com sucesso',
  editError: 'Um erro ocorreu ao editar o protesto. Por favor, tente novamente em breve.',
};

export const ProtestLettersPage = () => {
  // Permissions
  const hasPermission = permissions.vendemmiaProtestLetters;
  const hasPermissionSuppliers = permissions.vendemmiaSuppliers;

  // Instances
  const [list, setList] = useState([]);
  const [protest, setProtest] = useState(null);
  const [process, setProcess] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [formattedSuppliers, setFormattedSuppliers] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [identifier, setIdentifier] = useState();
  const [supplierIdentifiers, setSupplierIdentifiers] = useState();
  const [hasType, setHasType] = useState();
  const [documentPreview, setDocumentPreview] = useState();
  const [filterOptions, setFilterOptions] = useState([]);

  //triggers Modal
  const { isOpen: isOpenView, onOpen: onOpenView, onClose: onCloseView } = useDisclosure();

  //trigger Drawer
  const { isOpen: drawerEditIsOpen, onOpen: drawerEditOnOpen, onClose: drawerEditOnClose } = useDisclosure();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const [disabledButtons, setDisabledButtons] = useState([]);
  const [code, setCode] = useState([]);

  // Load
  const load = (filters, _key, page) => {
    getProtestLetters(filters, page);
    getProtestSuppliers(filters, page);
    getProcessList();
    setFilterOptions(filterOptionsProtest);
  };

  // Custom hook for refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  // Requests
  const getProcessList = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchProcessList(filters, page),
      setIsLoading,
    });
    setProcess(res.data);
  };

  const getProtestLetters = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchProtestLetters(filters, page),
      setIsLoading,
    });
    setList(res.data.data);
  };

  const getProtestSuppliers = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchProtestSuppliers(filters, page),
      setIsLoading,
    });
    setSuppliers(res.data.data);
  };

  const fetchProtestSuppliersDetails = async (identifiers) => {
    const supplierDataPromises = identifiers.map(async (identifier) => {
      return getProtestSuppliersDetails(identifier);
    });

    // Espera que todas as promessas terminem e armazena os dados dos fornecedores
    const suppliersData = await Promise.all(supplierDataPromises);

    // Remove qualquer fornecedor nulo, caso tenha ocorrido um erro na chamada
    return suppliersData.filter((supplier) => supplier !== null);
  };

  const getProtestSuppliersDetails = async (identifier) => {
    const res = await executeRequest({
      action: () => requests.getSupplierDetails(identifier),
      setIsLoading,
    });

    return res.data;
  };

  const showDocument = async (index) => {
    onOpenView();
    setDocumentPreview(
      handleDocuments(`/open/process/protest/${identifier}/supplier/${supplierIdentifiers[index]}/view`, 'protestLetters')
    );
  };

  const sendEmail = async (index) => {
    await executeRequest({
      action: () => requests.sendProtestEmail(identifier, supplierIdentifiers[index]),
      msgSuccess: MESSAGES.emailSuccess,
      msgError: MESSAGES.emailError,
      callback: () => {
        // Desativa o botão após o envio bem-sucedido
        setDisabledButtons((prev) => [...prev, index]);
      },
    });
  };

  const createNewProcess = async (data) => {
    const formattedData = await formattedDataAPI(data);

    await executeRequest({
      action: () => requests.addProtestLetter(formattedData),
      msgSuccess: MESSAGES.createSuccess,
      msgError: MESSAGES.createError,
      setIsLoading,
      triggerRefresh,
      callback: drawerEditOnClose,
    });
  };

  const editProcessLetter = async (identifier, data) => {
    const formattedData = await formattedDataAPI(data);

    await executeRequest({
      action: () => requests.editProtestLetterDetails(identifier, formattedData),
      msgSuccess: MESSAGES.editSuccess,
      msgError: MESSAGES.editError,
      setIsLoading,
      triggerRefresh,
      callback: drawerEditOnClose,
    });
  };

  //formatar valores enviados para as APIs
  const formattedDataAPI = async (data) => {
    const supplierIdentifiers = [];

    //populando o array com os identificadores dos fornecedores
    data.suppliers.forEach((supplier) => {
      supplier.identifier && supplierIdentifiers.push(supplier.identifier);
    });

    // Criando o objeto de dados final para envio à API
    const formattedData = {
      status: data.status || 'PENDENTE', // Usando o status do formulário ou um valor padrão
      date: data.date || '', // Usando a data do formulário ou um valor padrão
      suppliers: supplierIdentifiers, // Identificadores dos fornecedores
      process: process.find((item) => item.code === data.process)?.identifier || '', // Usando o código do processo ou um valor padrão
    };
    return formattedData;
  };

  // Actions
  const handleOpenEditModal = async (identifier) => {
    const res = await executeRequest({
      action: () => requests.getProtestLetterDetails(identifier),
      setIsLoading,
    });

    const { data = null } = res;
    setCode(data.code);

    setDisabledButtons([]);
    setIdentifier(identifier);
    setProtest(data);
    // Atualize initialValues e abra o Drawer
    updateInitialValues(data);
    addParametersToURL({ type: 'edit' });
  };

  const updateInitialValues = async (protestData) => {
    // Extraia os identificadores dos fornecedores associados ao processo
    const identifiersFromData = protestData.suppliers || [];
    setSupplierIdentifiers(identifiersFromData);

    // Buscar detalhes dos fornecedores associados ao processo
    const filteredSuppliers = await fetchProtestSuppliersDetails(identifiersFromData);

    // Mapeia os fornecedores para o formato necessário
    const mappedSuppliers = filteredSuppliers.map((supplier, index) => ({
      identifier: protestData.suppliers[index] || '',
      documentNumberFormatted: supplier.documentNumberFormatted || '',
      name: supplier.name || '',
      address: supplier.address || '',
      contacts: supplier.contacts || [],
    }));

    // Atualiza os valores iniciais do formulário combinando dados fixos do processo com os fornecedores
    const updatedValues = {
      status: protestData.status || 'PENDENTE',
      process: protestData.processCode || '',
      date: convertDateToInputFormat(protestData.dateFormatted, '/'),
      suppliers: mappedSuppliers,
    };

    // Não remover processos aqui; adicione todos os processos sem filtrar e adicione o processo atual no topo da lista
    const updatedProcessOptions = [
      { code: protestData.processCode, identifier: protestData.processIdentifier },
      ...process.filter((item) => item.code !== protestData.processCode), // Inclui os outros processos
    ];

    setIsDrawerOpen(false);
    setInitialValues(updatedValues);
    setFormattedSuppliers(filteredSuppliers);
    setProcess(updatedProcessOptions); // Defina todos os processos corretamente
    setIsDrawerOpen(true);
  };

  const resetInitialValues = () => {
    const updatedValues = {
      status: 'PENDENTE',
      process: '',
      date: '',
      suppliers: [],
    };
    setInitialValues(updatedValues);
    setIsDrawerOpen(true);
  };

  const onSubmitForm = (values) => {
    hasType === 'new' && createNewProcess(values);
    hasType === 'edit' && editProcessLetter(identifier, values);
  };

  const addNewProtest = () => {
    closeFloatActionButton();
    // Fechar o Drawer antes de abrir para novo registro
    if (drawerEditIsOpen) {
      drawerEditOnClose();
      setTimeout(() => {
        resetInitialValues();
        addParametersToURL({ type: 'new' });
      }, 300); // Pequeno atraso para garantir que o Drawer seja fechado completamente
    } else {
      resetInitialValues();
      addParametersToURL({ type: 'new' });
    }
  };

  //button FAB
  const stylesButtonFAB = {
    _hover: { bgColor: '#70D499' },
    bg: 'green',
    p: '1.5rem 1.3rem',
    borderRadius: '50px',
  };

  // Component preset
  const customButtons = [
    {
      main: [{ label: 'Editar', tooltip: 'Editar protesto', icon: <MdOpenInNew size={20} />, action: handleOpenEditModal }],
      collapsed: [],
    },
  ];

  const fetchOptionsProcess = (filters) => {
    getProcessList({ search: filters });
  };

  const fetchOptionsSuppliers = (filters) => {
    getProtestSuppliers({ search: filters });
  };

  // Atualiza o hasType quando o parâmetro de URL muda
  useEffect(() => {
    const urlType = hasSpecificURLParameters(['type']).value;
    setHasType(urlType);
  }, [window.location.href]);

  useEffect(() => {
    if (initialValues) {
      // Só abre o Drawer se initialValues estiver definido
      drawerEditOnOpen();
    }
  }, [initialValues]);

  return (
    <Page
      screen="protestLetters"
      title="Cartas de protesto"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="processo, empresa ou ref"
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      filterOptions={filterOptions}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      showFilters={true}
      showPeriodFilter={true}
      FAB={[
        {
          title: 'Cadastrar consulta tributária',
          text: 'Cadastro de consulta tributária',
          action: (
            <Button leftIcon={<MdAdd color="#FFFFFF" size={20} />} onClick={addNewProtest} {...stylesButtonFAB}>
              <Text mr="10px" color="white">
                Carta de protesto
              </Text>
            </Button>
          ),
          modality: 'custom',
        },
        hasPermissionSuppliers && {
          title: 'Cadastrar consulta tributária',
          text: 'Cadastro de consulta tributária',
          action: (
            <Button leftIcon={<MdAccountCircle color="#FFFFFF" size={20} />} {...stylesButtonFAB}>
              <Link href={'/vendemmia/suppliers'} target="_blank" rel="noopener noreferrer" _hover={{ textDecoration: 'none' }}>
                <Text mr="10px" color="white">
                  Fornecedores
                </Text>
              </Link>
            </Button>
          ),
          modality: 'custom',
        },
      ].filter(Boolean)}>
      <CommonList
        list={list}
        rawData={list}
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        customButtons={customButtons}
        isLoading={isLoading}
        {...commonListConfig}
      />
      <CommonDrawer
        placement="right"
        heading={hasType === 'new' ? 'Nova Carta de Protesto' : 'Editar Carta de Protesto'}
        headingFlag={hasType === 'edit' ? `# ${code}` : null}
        isOpen={drawerEditIsOpen && isDrawerOpen}
        onOpen={drawerEditOnOpen}
        onClose={() => {
          drawerEditOnClose();
          setIsDrawerOpen(false); // Resetar o estado para garantir a abertura correta na próxima vez
        }}>
        <DynamicForm config={{ initialValues, validationSchema, callback: onSubmitForm }}>
          <DynamicFormBody>
            <DynamicFormProtestLetters
              suppliers={formattedSuppliers}
              suppliersList={suppliers}
              processList={process}
              showDocument={showDocument}
              sendEmail={sendEmail}
              fetchOptionsProcess={fetchOptionsProcess}
              fetchOptionsSuppliers={fetchOptionsSuppliers}
              disabledButtons={disabledButtons}
              type={hasType}
            />
          </DynamicFormBody>
          <DynamicFormFooter>
            <DynamicFormButton name={'Salvar'} type="button" loadingText={'Salvando'} isLoading={isLoading} />
          </DynamicFormFooter>
        </DynamicForm>
      </CommonDrawer>
      <CommonModal isOpen={isOpenView} onOpen={onOpenView} onClose={onCloseView} closeButton={true} fullpage={true}>
        {documentPreview}
      </CommonModal>
    </Page>
  );
};
